
.TIE .TIE {
  background-color: yellow !important;
  color: black !important;
}

.pg-AFC {
  background-color: #d50a0a;

  color: white;
}
.pg-NFC {
  background-color: #013369;
  color: white;
}

/* You can add global styles to this file, and also import other style files */
@import './variables';
.button-row{
  display:table-cell;
  .mat-button-base{
    margin:8px 8px 8px 0;
  }
}
body {
  margin: 0;
  line-height: 1.42857143;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.mat-table {
  margin: 5px 0;
  background: none;
  width: 100%;
  max-width: 75%;
}

[mat-row]:nth-child(even) td {
  background-color: none;
}
body:not(.darkMode){
  [mat-row]:nth-child(odd) td {
    background-color: white;
  }
  .faded {
    opacity: 0.6;
  }
  .indicator-mini-bye{
    color: red;
  }
}

body.darkMode{
  .indicator-mini-bye{
    color: yellow;
  }
  .faded{
    opacity: 0.8;
  }
  mat-sidenav-container a{
    color:#ce93d8
  }
  a:visited{
    color:white;
  }
}

.in-post-season {
  color: green;
  font-weight: bold;
}
.out-post-season {
  color: red;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
}
.flex-fluid {
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 0 0 40px;
}
.width-50 {
  width: 50%;
}
.width-20{
  width:20%;
}
tr.mat-header-row {
  height: 1em !important;
}
.text-center {
  text-align: center;
}

::ng-deep .mat-tab-group{
  box-sizing: border-box;
}
::ng-deep .mat-drawer-content{
  overflow-x:hidden;
}
::ng-deep .mat-tab-list{
  flex-grow:unset !important;
}
.mat-tooltip  {
  white-space: pre-line;
}


@media(max-width:680px){
  .hide-xs{
    display:none;
  }

  .visible-xs{
   display: block;
  }
}
@media(min-width:680px){
  .hide-xs{
    display:block;
  }.visible-xs{
    display: none;
   }
}

/* Parent container styles */
.scroll-container-wrapper {
  position: relative;
}

/* Scrollable element styles */
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}

/* Shadow effect for scroll indication */
.scroll-container-wrapper::before,
.scroll-container-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none;
  z-index: 1;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  display: none;
}

/* Left shadow (only visible when not scrolled to the beginning) */
.scroll-container-wrapper.scroll-left::before {
  left: 0;
  display: block;
}

/* Right shadow (only visible when not scrolled to the end) */
.scroll-container-wrapper.scroll-right::after {
  right: 0;
  display: block;
}

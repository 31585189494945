@use '@angular/material' as mat;
@import "@angular/material/theming";
$primary: mat.define-palette(mat.$blue-palette, 900);
$accent: mat.define-palette(mat.$indigo-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

$angular-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);

$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);


.darkMode {
    @include mat.all-component-colors($angular-dark-theme);
    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
      color: white;
    }
    .mat-primary .mat-pseudo-checkbox-checked{
      background:white;
    }
  }